import React from "react";
import twin from "../../assets/images/updates/twin.jpg";

const ProjectsBody = () => {
  return (
    <section className=" section-spacing-80 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content width-600 white-bg box-shadow relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-80">
                <div className="padding-16 center">
                  <div className="section-head section-spacing-20">
                    <h3 className="uppercase bordered-bottom-4">
                      Projects that NMAU is invested or partnering.
                    </h3>
                  </div>
                  <div className="gallery-container">
                    <div className="update-card box-shadow padding-4">
                      <h4 className="update-card-title margin-bottom-10 padding-16">
                        The Twin To Win Project
                      </h4>
                      <img
                        src={twin}
                        className="box-shadow"
                        alt="Twin To Win Project"
                      />
                      <div className="update-details">
                        <p className="section-spacing-10">
                          <span className="block margin-bottom-4">
                            Read More About The Twin to Win Project:
                          </span>
                          <span className="bold">
                            <a href="/https://internationalmidwives.org/icm-projects/twin-to-win-pilot-project.html">
                              Click Here
                            </a>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectsBody;
