import React from "react";
import abstract2023 from "../../assets/images/updates/idm2023abstract.jpg";
import idm2023soroti from "../../assets/images/updates/idm2023soroti.jpg";
import agm from "../../assets/images/updates/agm2023.jpg";
import idm2023toolkit from "../../assets/images/updates/idm2023ToolKit.pdf";
import u1 from "../../assets/images/gallery/u1.jpg";

const UpdatesBody = () => {
  return (
    <section className="section-spacing-80 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content width-600 white-bg box-shadow relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-80">
                <div className="padding-16 center">
                  <div className="section-head section-spacing-20">
                    <h3 className="uppercase bordered-bottom-4">
                      Important communications from NMAU
                    </h3>
                  </div>
                  <div className="gallery-container">
                    <div className="update-card box-shadow padding-4">
                      <h4 className="update-card-title margin-bottom-10 padding-16">
                        NMAU's hosting of The Swedish International Development
                        Cooperation Agency (SIDA) and The United Nations Fund
                        for Population Activities (UNFPA) at Naguru Hospital
                      </h4>
                      <img
                        src={u1}
                        className="box-shadow"
                        alt="SIDA, UNFPA, NMAU meeting at Naguru Hospital on 9th Feb 2024"
                      />
                      <div className="update-details">
                        <h4 className="uppercase bordered-bottom-4 margin-bottom-10 center">
                          Update Details
                        </h4>
                        <p className="section-spacing-10">
                          NMAU hosted officials from The Swedish International
                          Development Cooperation Agency (SIDA) and The United
                          Nations Fund for Population Activities (UNFPA) at
                          Naguru hospital where the Association progress and
                          challenges were discussed.
                        </p>
                        <p className="section-spacing-10">
                          The meeting had representatives from SIDA, UNFPA,
                          ACORD, ICM, MoH, NMAU National executive committee ,
                          The Midwize Champions, The young midwife Leaders,
                          obstetricians, midwives and midwives friends.
                        </p>
                        <p className="section-spacing-10">
                          <span className="block margin-bottom-4">Date:</span>
                          <span>9th FEB 2024</span>
                        </p>
                        <p className="section-spacing-10">
                          <span className="block margin-bottom-4">Venue:</span>
                          <span className="bold">Naguru Hospital</span>
                        </p>
                      </div>
                    </div>
                    <div className="update-card box-shadow padding-4">
                      <h4 className="update-card-title margin-bottom-10 padding-16">
                        Annual General Meeting OCTOBER 2023
                      </h4>
                      <img
                        src={agm}
                        className="box-shadow"
                        alt="Annual General Meeting OCT 2023"
                      />
                      <div className="update-details">
                        <h4 className="uppercase bordered-bottom-4 margin-bottom-10 center">
                          Update Details
                        </h4>
                        <p className="section-spacing-10">
                          <span className="block margin-bottom-4">Date:</span>
                          <span>20th OCT 2023</span>
                        </p>
                        <p className="section-spacing-10">
                          <span className="block margin-bottom-4">Time:</span>
                          <span className="bold">8AM-6PM EAT</span>
                        </p>
                        <p className="section-spacing-10">
                          <span className="block margin-bottom-4">Venue:</span>
                          <span className="bold">Grand Global Hotel</span>
                        </p>
                        <p className="section-spacing-10">
                          <span className="block margin-bottom-4">
                            AGM Concept Note:
                          </span>
                          <span className="bold">
                            <a href="/agm-concept-note">Click Here</a>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="update-card box-shadow padding-4">
                      <h4 className="update-card-title margin-bottom-10 padding-16">
                        The 2023 International Day of the Midwife (IDM)
                        Symposium &amp; Celebration Call for Abstracts, Uganda
                      </h4>
                      <img
                        src={abstract2023}
                        className="box-shadow"
                        alt="IDM 2023 Abstracts Call"
                      />
                      <div className="update-details">
                        <h4 className="uppercase bordered-bottom-4 margin-bottom-10 center">
                          Update Details
                        </h4>
                        <p className="section-spacing-10">
                          <span className="block margin-bottom-4">
                            Submit Abstracts To:
                          </span>
                          <span>
                            <a href="mailto:idmsymposium2023@gmail.com">
                              idmsymposium2023@gmail.com
                            </a>
                          </span>
                        </p>
                        <p className="section-spacing-10">
                          <span className="block margin-bottom-4">
                            Deadline:
                          </span>
                          <span className="bold">
                            15th April, 2023, 23:59 Hours EAT
                          </span>
                        </p>
                        <p className="section-spacing-10">
                          <span className="block margin-bottom-4">
                            For More Details about the Abstract:
                          </span>
                          <span>
                            <a
                              href="https://lnnk.in/hCgk"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Click here
                            </a>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="update-card box-shadow padding-4">
                      <h4 className="update-card-title margin-bottom-10 padding-16">
                        The 2023 International Day of the Midwife (IDM)
                        Celebrations, Soroti Grounds, Uganda
                      </h4>
                      <img
                        src={idm2023soroti}
                        className="box-shadow"
                        alt="The 2023 International Day of the Midwife (IDM)
                        Celebrations, Soroti Grounds, Uganda"
                      />
                      <div className="update-details">
                        <h4 className="uppercase bordered-bottom-4 margin-bottom-10 center">
                          Update Details
                        </h4>
                        <p className="section-spacing-10">
                          <span className="block margin-bottom-4">Theme:</span>
                          <span>Together again: From evidence to reality</span>
                        </p>
                        <p className="section-spacing-10">
                          <span className="block margin-bottom-4">
                            Date (Midwives day):
                          </span>
                          <span className="bold">5th May, 2023</span>
                        </p>
                        <p className="section-spacing-10">
                          <span className="block margin-bottom-4">
                            Symposium:
                          </span>
                          <span className="bold">
                            Akello Hotel, 3rd-4th May 2023
                          </span>
                        </p>
                        <p className="section-spacing-10">
                          <span className="block margin-bottom-4">Venue:</span>
                          <span className="bold">Soroti Sports Ground</span>
                        </p>
                      </div>
                    </div>
                    <div className="update-card box-shadow padding-4">
                      <h4 className="update-card-title margin-bottom-10 padding-16">
                        The 2023 International Day of the Midwife (IDM) ADVOCACY
                        TOOLKIT AND RESOURCES PACK
                      </h4>
                      <div className="update-details">
                        <h4 className="uppercase bordered-bottom-4 margin-bottom-10 center">
                          Update Details
                        </h4>

                        <p className="section-spacing-10">
                          <span className="block margin-bottom-4">
                            Download ToolKit Here:
                          </span>
                          <span>
                            <a
                              href={idm2023toolkit}
                              target="_blank"
                              download
                              rel="noopener noreferrer"
                            >
                              Click here
                            </a>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpdatesBody;
