import React from "react";

import AboutSnippet from "../components/home/AboutSnippet";
import Contacts from "../components/home/Contacts";
import Faqs from "../components/home/Faqs";
import HomeHero from "../components/home/HomeHero";
import Team from "../components/home/Team";

import { Helmet } from "react-helmet";
import Welcome from "../components/home/Welcome";

export default function Home() {
  return (
    <>
      <Helmet>
        <link
          rel="conanical"
          href="https://www.nmau.org/"
        />
        <meta
          name="description"
          content="This is the official website for The National Midwives Association of Uganda"
        />
        <meta
          name="og:title"
          content="Home | The National Midwives Association of Uganda"
        />
        <meta
          name="og:url"
          content="https://NationalMidwivesAssociationUganda.org/"
        />
        <meta
          name="og:description"
          content="This is the official website for The National Midwives Association of Uganda"
        />
        <meta
          name="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1680601031/midwives_muk7rw.jpg"
        />
        <meta
          name="twitter:title"
          content="Home | The National Midwives Association of Uganda"
        />
        <meta
          name="twitter:text:title"
          content="Home | The National Midwives Association of Uganda"
        />
        <meta
          name="twitter:description"
          content="This is the official website for The National Midwives Association of Uganda"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1680601031/midwives_muk7rw.jpg"
        />

        <title>Home | The National Midwives Association of Uganda</title>
      </Helmet>
      <HomeHero />
      <Welcome />
      <AboutSnippet />
      <Team />
      <Faqs />
      <Contacts />
   
      {/*<WhyCMC />*/}
    </>
  );
}
