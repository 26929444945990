import React from "react";
const PageHero = (props) => {
  return (
    <section className={props.classes}>
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="page-hero section-height-300 centered">
              <div className=" centered-inner">
                <div className="title-container width-600 margin-auto center">
                  <h3 className={props.titleClasses}>{props.title}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageHero;
