import React from "react";
import AboutBody from "../components/about/AboutBody";
import PageHero from "../components/reusables/hero/PageHero";
import Contacts from "../components/home/Contacts";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <link
          rel="conanical"
          href="https://www.nmau.org/about"
        />
        <meta
          name="description"
          content="About The National Midwives Association of Uganda"
        />
        <meta
          property="og:description"
          content="About The National Midwives Association of Uganda"
        />
        <meta
          property="og:title"
          content="About | The National Midwives Association of Uganda"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1680601031/midwives_muk7rw.jpg"
        />

        <meta
          name="twitter:title"
          content="About | The National Midwives Association of Uganda"
        />
        <meta
          name="twitter:text:title"
          content="About | The National Midwives Association of Uganda"
        />
        <meta
          name="twitter:description"
          content="About The National Midwives Association of Uganda"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1680601031/midwives_muk7rw.jpg"
        />
        <title>The National Midwives Association of Uganda | ABOUT</title>
      </Helmet>
      <PageHero
        classes="dark-bg"
        titleClasses="title font-size-25 uppercase white"
        title="About NMAU"
      />
      <AboutBody />
      <Contacts />
    </>
  );
};

export default About;
