import React from "react";
import Contacts from "../components/home/Contacts";
import PageHero from "../components/reusables/hero/PageHero";
import { Helmet } from "react-helmet";
import UpdatesBody from "../components/updates/UpdatesBody";

const Updates = () => {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.nmau.org/updates" />
        <meta
          name="description"
          content="Important and urgent communications from The National Midwives Association of Uganda"
        />
        <meta
          property="og:description"
          content="Important and urgent communications from The National Midwives Association of Uganda"
        />
        <meta property="og:title" content="NMAU Updates" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1680601031/midwives_muk7rw.jpg"
        />

        <meta name="twitter:title" content="NMAU Updates" />
        <meta name="twitter:text:title" content="NMAU Updates" />
        <meta
          name="twitter:description"
          content="Important and urgent communications from The National Midwives Association of Uganda"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1680601031/midwives_muk7rw.jpg"
        />
        <title>NMAU Updates</title>
      </Helmet>
      <PageHero
        classes="dark-bg"
        titleClasses="title font-size-25 uppercase white"
        title="NMAU Updates"
      />
      <UpdatesBody />
      <Contacts />
    </>
  );
};

export default Updates;
