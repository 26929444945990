import React from "react";
import Contacts from "../components/home/Contacts";
import PageHero from "../components/reusables/hero/PageHero";
import { Helmet } from "react-helmet";
import TeamBody from "../components/team/TeamBody";

const Team = () => {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.nmau.org/team" />
        <meta
          name="description"
          content="The National Midwives Association of Uganda's Leadership team,structure and committees"
        />
        <meta
          property="og:description"
          content="The National Midwives Association of Uganda's Leadership team,structure and committees"
        />
        <meta
          property="og:title"
          content="The National Midwives Association of Uganda's Team"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1680601031/midwives_muk7rw.jpg"
        />

        <meta
          name="twitter:title"
          content="The National Midwives Association of Uganda's Team"
        />
        <meta
          name="twitter:text:title"
          content="The National Midwives Association of Uganda's Team"
        />
        <meta
          name="twitter:description"
          content="The National Midwives Association of Uganda's Leadership team,structure and committees"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1680601031/midwives_muk7rw.jpg"
        />
        <title>The National Midwives Association of Uganda's Team</title>
      </Helmet>
      <PageHero
        classes="dark-bg"
        titleClasses="title font-size-25 uppercase white"
        title="NMAU's Leadership Team and Committees"
      />
      <TeamBody />
      <Contacts />
    </>
  );
};

export default Team;
