import React from "react";

import twitter from "../../assets/images/icons/twitter.svg";
import facebook from "../../assets/images/icons/facebook.svg";
import instagram from "../../assets/images/icons/instagram.svg";

const TeamCardNo = (props) => {
  return (
    <div className="team-card">
      <div className="team-image">
        <img src={props.teamImg} alt={props.name} />
      </div>
      <div className="bars bar1"></div>
      <div className="bars bar2"></div>
      <div className="bars bar3"></div>
      <div className="team-details padding-8 no-padding-bottom center">
        <div className="team-name">
          <h3 className="title font-size-20 uppercase">{props.name}</h3>
        </div>
        <p className="team-bio uppercase">{props.title}</p>
        <div className="team-social-icons flex">
          <p className="flex align-center justify-center">
            <img src={twitter} alt="Twitter" className="icon" />
          </p>
          <p className="flex align-center justify-center">
            <img src={facebook} alt="Facebook" className="icon" />
          </p>
          <p className="flex align-center justify-center">
            <img src={instagram} alt="Instagram" className="icon" />
          </p>
        </div>
        {/*
        <div className="team-footer no-padding-bottom">
          <p>
            Current Employment: <br></br>
            <span className="bold font-size-18">{props.job}</span>
          </p>
        </div>
        */}
      </div>
    </div>
  );
};

export default TeamCardNo;
