import React from "react";
import twitter from "../../assets/images/icons/twitter.svg";
import facebook from "../../assets/images/icons/facebook.svg";
import instagram from "../../assets/images/icons/instagram.svg";

const Contacts = () => {
  return (
    <section className=" section-spacing-80" id="contact">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className=" section-spacing-10">
              <div className=" title-container section-spacing-30 no-padding-top">
                <h4 className="title font-size-25 uppercase center">
                  You can reach out
                </h4>
              </div>
            </div>
            <div className="section-body">
              <div className="contacts-container flex space-flex-wrap font-size-16">
                <div className="address">
                  <h4 className="uppercase font-size-20 margin-bottom-10 bordered-bottom-4">
                    Contacts/Address
                  </h4>
                  <div className="brand-address">
                    <p>
                      +256 786 961 922 <br></br>{" "}
                      nationalmidwivesassociationug@gmail.com
                      <span className="block">
                        Plot 1255 Kireka Kamuli, Block No 229 <br></br>P.O. Box
                        29520, Kampala Uganda
                      </span>
                    </p>
                    <div className="section-body section-spacing-10">
                      <div className="map-content">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d127671.7281264389!2d32.56424337133878!3d0.3555402055749495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177db9bbfd9b9ac7%3A0x83ad04ffc3606634!2sNational%20Midwives%20Association%20of%20Uganda%20(NMAU)!5e0!3m2!1sen!2sug!4v1682926142007!5m2!1sen!2sug"
                          style={{ border: 0 }}
                          allowfullscreen="true"
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="working">
                  <h4 className="uppercase font-size-20 margin-bottom-10 bordered-bottom-4">
                    Working hours
                  </h4>
                  <div className="working-inner">
                    <p className="margin-bottom-10">
                      <span>Mon-Fri:</span>
                      <span className="bold left-push-4">
                        09:00AM - 05:00PM
                      </span>
                    </p>
                    <p className="margin-bottom-10">
                      <span>Saturday:</span>
                      <span className="bold left-push-4">CLOSED</span>
                    </p>
                    <p className="margin-bottom-10">
                      <span>Sunday:</span>
                      <span className="bold left-push-4">CLOSED</span>
                    </p>
                  </div>
                </div>
                <div className="social">
                  <h4 className="uppercase font-size-20 margin-bottom-10 bordered-bottom-4">
                    Follow Us
                  </h4>
                  <div className="socials">
                    <a
                      href="https://twitter.com/NatMidwivesAsUg"
                      target="_blank"
                      className="flex dark"
                    >
                      <img src={twitter} alt="Twitter" className="icon" />
                      <span className=" left-push-4">NatMidwivesAsUg</span>
                    </a>
                    <a
                      href="https://facebook.com/NatMidwivesAsUg"
                      target="_blank"
                      className="flex dark margin-top-10"
                    >
                      <img src={facebook} alt="Facebook" className="icon" />
                      <span className=" left-push-4">
                        National Midwives Association Uganda
                      </span>
                    </a>
                    <a
                      href="#"
                      target="_blank"
                      className="flex dark margin-top-10"
                    >
                      <img src={instagram} alt="Instagram" className="icon" />
                      <span className=" left-push-4">
                        National Midwives Association Uganda
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-footer section-spacing-40 no-padding-bottom">
              <div className="cta-container center section-spacing-20 no-padding-top">
                <a
                  href="https://form.jotform.com/nmauwebsite/contact-us"
                  className=" bold more-button green width-48"
                >
                  Get in Touch
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
