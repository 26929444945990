import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings } from "../reusables/Constants";
import eve from "../../assets/images/team/pres2.jpg";
import vp from "../../assets/images/team/5.jpg";
import treas from "../../assets/images/team/2.jpg";
import n4 from "../../assets/images/team/4.jpeg";
import TeamCardNo from "../reusables/TeamCardNo";


const Team = () => {
  return (
    <section className="section-spacing-100 threads-bg">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-head">
              <div className="side-head">
                <div className="title-container">
                  <h3 className="section-title title uppercase">
                    our leadership
                  </h3>
                </div>
              </div>
            </div>
            <div className="section-body section-spacing-40">
              <div className="slider-items-container center-slide-arrows flex space-flex-wrap">
                <Slider {...settings}>
                  <TeamCardNo
                    teamImg={eve}
                    name="Kanyunyuzi Annette Evelyn"
                    title="President"
                  ></TeamCardNo>
                  <TeamCardNo
                    teamImg={vp}
                    name="LIKICO EMILY OPU"
                    title="Vice President"
                  ></TeamCardNo>
                  <TeamCardNo
                    teamImg={treas}
                    name="RITAH NIWAMANYA"
                    title="National Treasurer"
                  ></TeamCardNo>
                  <TeamCardNo
                    teamImg={n4}
                    name="Nalujja Joselyne Kuteesa"
                    title="General Executive Secretary"
                  ></TeamCardNo>{" "}
                </Slider>
              </div>
            </div>
            <div className="section-footer section-spacing-20">
              <div className="cta-container center">
                <a href="/team" className=" bold green-bg white width-48">
                  View Full Team
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
