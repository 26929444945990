import React from "react";
import eve from "../../assets/images/team/pres2.jpg";
import vp from "../../assets/images/team/5.jpg";
import treas from "../../assets/images/team/2.jpg";
import anitah from "../../assets/images/content/anita.jpg";
import hadi from "../../assets/images/content/hadi.jpg";
import n1 from "../../assets/images/team/1.jpeg";
import n2 from "../../assets/images/team/2.jpeg";
import n3 from "../../assets/images/team/3.jpeg";
import n4 from "../../assets/images/team/4.jpeg";
import n5 from "../../assets/images/team/5.jpeg";
import n6 from "../../assets/images/team/6.jpeg";
import n8 from "../../assets/images/team/3.jpg";
import n9 from "../../assets/images/team/4.jpg";
import TeamCardNo from "../reusables/TeamCardNo";

const TeamBody = () => {
  return (
    <section className=" section-spacing-80 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content width-800 white-bg box-shadow relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-80">
                <div className=" padding-16">
                  <div className="com">
                    <div className="images-content">
                      <h3 className=" title font-size-25 uppercase bordered-bottom-4">
                        NATIONAL EXECUTIVE COMMITTEE 2023-2027 FOR NATIONAL
                        MIDWIVES ASSOCIATION OF UGANDA
                      </h3>
                      <div className="team-cards-container section-spacing-40 no-padding-bottom flex space-flex-wrap">
                        <TeamCardNo
                          teamImg={eve}
                          name="Kanyunyuzi Annette Evelyn"
                          title="President"
                        ></TeamCardNo>
                        <TeamCardNo
                          teamImg={vp}
                          name="LIKICO EMILY OPU"
                          title="Vice President"
                        ></TeamCardNo>
                        <TeamCardNo
                          teamImg={treas}
                          name="RITAH NIWAMANYA"
                          title="National Treasurer"
                        ></TeamCardNo>
                        <TeamCardNo
                          teamImg={n4}
                          name="Nalujja Joselyne Kuteesa"
                          title="General Executive Secretary"
                        ></TeamCardNo>
                        <TeamCardNo
                          teamImg={n2}
                          name="Humphrey Beja"
                          title="Deputy Secretary"
                        ></TeamCardNo>
                        <TeamCardNo
                          teamImg={n3}
                          name="Ahimbisibwe Esther"
                          title="Regional Coordinator, East Central"
                        ></TeamCardNo>
                        <TeamCardNo
                          teamImg={n6}
                          name="Namakula Ritah"
                          title="Regional Coordinator, Central"
                        ></TeamCardNo>
                        <TeamCardNo
                          teamImg={n1}
                          name="Mwesigwa Peruth"
                          title="Regional Coordinator, South Western"
                        ></TeamCardNo>
                        <TeamCardNo
                          teamImg={n5}
                          name="Barbra Muga "
                          title="Regional Coordinator, Northern"
                        ></TeamCardNo>
                        <TeamCardNo
                          teamImg={n8}
                          name="WEBOMBESA ANTONINA"
                          title="Regional Coordinator, Elgon"
                        ></TeamCardNo>
                        <TeamCardNo
                          teamImg={n9}
                          name="ANYAIT EUNICE"
                          title="Regional Coordinator, TESO-KARAMOJA"
                        ></TeamCardNo>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamBody;
