import React from "react";
const Footer = () => {
  return (
    <section className="section-spacing-20 green-bg threads-bg no-padding-bottom">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <ul className=" footer-menu flex">
                <li className="footer-menu-item footer-about">
                  <h3 className="footer-menu-title">About</h3>
                  <div className="footer-subtitle light-gray">
                    <p>
                      It is valuable to acknowledge that the best link for
                      midwives in both the public and private sectors is NMAU
                      which is a one-stop center for all Midwives and
                      well-wishers of Midwives.
                    </p>
                    <p className=" margin-top-10">
                      The Association's growth is on good trajectory and looks
                      forward providing a greater platform for midwives to
                      Advocate, Participate in Health Policy matters, Grow in
                      Knowledge and skills, Innovate and generate new knowledge,
                      Network and collaborate. Stronger Together Championing
                      Midwifery
                    </p>
                    <p className=" margin-top-10">Become a Member today :)</p>
                  </div>
                </li>
                <li className="footer-menu-item footer-useful">
                  <h3 className="footer-menu-title">Useful Links</h3>
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="https://internationalmidwives.org/icm-projects/twin-to-win-pilot-project.html">
                        Projects
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://form.jotform.com/nmauwebsite/NMAU-MemberRegistrationForm"
                        target="_blank"
                      >
                        Become a Member
                      </a>
                    </li>

                    <li>
                      <a href="https://form.jotform.com/nmauwebsite/nmau-feedback-form">
                        Give Your Feedback
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://form.jotform.com/nmauwebsite/nmau-newsletter-subscribe"
                        target="_blank"
                      >
                        Subscribe to Newsletter
                      </a>
                    </li>
                    <li>
                      <a href="/frequently-asked-questions">
                        Frequently Asked Questions
                      </a>
                    </li>

                    <li>
                      <a href="/team">Leadership Team & Committees</a>
                    </li>
                  </ul>
                </li>
                <li className="footer-menu-item width-300">
                  <h3 className="footer-menu-title">Our Projects</h3>
                  <ul className="footer-books">
                    <li>
                      <a href="/projects/agri-business">
                        <span className="book-title-footer">
                          Midwives SACCO{" "}
                        </span>
                        <span className="book-subtitle-footer">
                          Coming soon.
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="https://internationalmidwives.org/icm-projects/twin-to-win-pilot-project.html">
                        <span className="book-title-footer">
                          TWIN TO WIN PROJECT
                        </span>
                        <span className="book-subtitle-footer">
                          Twinning is a cross-cultural, reciprocal process where
                          two groups of people work together to achieve joint
                          goals
                        </span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="copyright margin-top-20 center light-gray">
              <p>
                &copy; 2023 The National Midwives Association of Uganda, All
                rights reserved
              </p>
            </div>
            <div className="designer section-spacing-10">
              <div className="design-inner center dark-bg">
                <p>
                  Website designed by{" "}
                  <a href="https://ntuna.com" className="link">
                    NTUNA.COM
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
