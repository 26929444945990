import React from "react";
import Slider from "react-slick";
import { Slide } from "react-reveal";
import logo from "../../assets/images/logo/cmc.png";
import wall from "../../assets/images/content/midwives.jpeg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings1 } from "../reusables/carousel";
import CarCard from "../reusables/CarCard";

const HomeHero = () => {
  return (
    <section className="hero">
      <div className="carous">
        <div className="slider-items-container center-slide-arrows">
          <Slider {...settings1}>
            <CarCard bgImg={wall}>
              <Slide right>
                <div className="hero-content-container">
                  <div className="hero-content-inner threads-bg">
                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick-title">
                        <p className=" bold dark">
                          <img src={logo} alt="CMC Logo" className="logo" />
                        </p>
                      </div>
                      <div className="brick threads-bg"></div>
                    </div>
                    <div className="hero-body section-spacing-30 dark">
                      <p className="center">
                        THE NATIONAL MIDWIVES ASSOCIATION OF UGANDA is a
                        Uganda's Midwives association, commited to empowering
                        members by increasing their competencies, building them
                        to the policy table, making them visible and harnessing
                        their innovativeness to solve reproductive, maternal,
                        adolscent, newborn and child health challenges.
                      </p>
                    </div>
                    <div className="hero-footer section-spacing-40 no-padding-top">
                      <div className="ctas-container flex">
                        <a
                          target="_blank"
                          href="/updates"
                          className="button green bold white-bg"
                        >
                          NMAU Updates
                        </a>
                        <a
                          href="https://form.jotform.com/nmauwebsite/NMAU-MemberRegistrationForm"
                          target="_blank"
                          className="button green-bg white bold"
                        >
                          Become a Member
                        </a>
                      </div>
                    </div>

                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick threads-bg"></div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            {/*
            <CarCard bgImg={health}>
              <Slide left>
                <div className="hero-content-container">
                  <div className="hero-content-inner threads-bg">
                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick-title"></div>
                      <div className="brick threads-bg"></div>
                    </div>
                    <div className="hero-body section-spacing-30 dark">
                      <p className="center bold">HEALTH</p>
                      <p className="center margin-top-10  font-size-16">
                        With intend to construct health facilities in agreed
                        upon areas and also partner with several health services
                        and plans providers to ensure good health in
                        communities.
                      </p>
                    </div>

                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick threads-bg"></div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            <CarCard bgImg={goat2}>
              <Slide left>
                <div className="hero-content-container">
                  <div className="hero-content-inner threads-bg">
                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick-title">
                        <p className=" bold dark"></p>
                      </div>
                      <div className="brick threads-bg"></div>
                    </div>
                    <div className="hero-body section-spacing-30 dark">
                      <p className="center bold font-size-16">AGRI-BUSINESS</p>
                      <p className="center margin-top-10">
                        We are investing in Goats farming where we rear a number
                        of goat breeds that will provide meat for CMC's meat
                        processing factory.
                      </p>
                    </div>

                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick threads-bg"></div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            <CarCard bgImg={school}>
              <Slide right>
                <div className="hero-content-container">
                  <div className="hero-content-inner threads-bg">
                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick-title">
                        <p className=" bold dark"></p>
                      </div>
                      <div className="brick threads-bg"></div>
                    </div>
                    <div className="hero-body section-spacing-30 dark">
                      <p className="center bold font-size-16">EDUCATION</p>
                      <p className="center margin-top-10">
                        We intend to invest in Education at all levels to put a
                        block on the social development of communities.
                      </p>
                    </div>
                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick threads-bg"></div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            <CarCard bgImg={apart2}>
              <Slide left>
                <div className="hero-content-container">
                  <div className="hero-content-inner threads-bg">
                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick-title"></div>
                      <div className="brick threads-bg"></div>
                    </div>
                    <div className="hero-body section-spacing-30 dark">
                      <p className="center bold font-size-16">RENTALS</p>
                      <p className="center margin-top-10">
                        We intend to provide affordable &amp; quality
                        accomodation services through our rentals project.
                      </p>
                    </div>
                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick threads-bg"></div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>

            */}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
