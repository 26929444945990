import React from "react";
import AboutBody from "../components/about/AboutBody";
import PageHero from "../components/reusables/hero/PageHero";
import Contacts from "../components/home/Contacts";
import { Helmet } from "react-helmet";
import AgmBody from "../components/agm/AgmBody";

const Agm = () => {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.nmau.org/agm-concept-note" />
        <meta name="description" content="NMAU AGM CONCEPT NOTE" />
        <meta property="og:description" content="NMAU AGM CONCEPT NOTE" />
        <meta property="og:title" content="NMAU AGM CONCEPT NOTE" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1680601031/midwives_muk7rw.jpg"
        />

        <meta name="twitter:title" content="NMAU AGM CONCEPT NOTE" />
        <meta name="twitter:text:title" content="NMAU AGM CONCEPT NOTE" />
        <meta name="twitter:description" content="NMAU AGM CONCEPT NOTE" />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1680601031/midwives_muk7rw.jpg"
        />
        <title>NMAU AGM CONCEPT NOTE</title>
      </Helmet>
      <PageHero
        classes="dark-bg"
        titleClasses="title font-size-25 uppercase white"
        title="CONCEPT NOTE FOR THE ANNUAL GENERAL MEETING OF THE NATIONAL MIDWIVES’ ASSOCIATION OF UGANDA (NMAU)"
      />
      <AgmBody />
      <Contacts />
    </>
  );
};

export default Agm;
