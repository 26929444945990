import React from "react";

const Welcome = () => {
  return (
    <section className=" section-spacing-10 green-bg">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <div className="welcome-content center flex align-center justify-center">
                <h2 className=" uppercase white font-size-20">
                  Welcome to THE NATIONAL MIDWIVES ASSOCIATION OF UGANDA
                </h2>
                <a
                  href="/team"
                  className="button bold more-button green auto left-push-10"
                >
                  View Our Leadership
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
