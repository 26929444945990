import React from "react";
const Faq = (props) => {
  return (
    <li className="faq">
      <details className="details" open>
        <summary className="summary dark">{props.faq}</summary>
        <div className=" margin-top-10 font-size-16">{props.children}</div>
      </details>
    </li>
  );
};

export default Faq;
