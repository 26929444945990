import React from "react";

const AboutBody = () => {
  return (
    <section className=" section-spacing-80 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content width-800 white-bg box-shadow relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-80">
                <div className="text-content bold">
                  <h3 className="title font-size-25 uppercase bordered-title white">
                    BACKGROUND
                  </h3>
                  <p className="section-spacing-20 no-padding-bottom">
                    THE NATIONAL MIDWIVES ASSOCIATION OF UGANDA (NMAU) is a
                    Uganda's Midwives association, commited to empowering
                    members by increasing their competencies, building them to
                    the policy table, making them visible and harnessing their
                    innovativeness to solve reproductive, maternal, adolscent,
                    newborn and child health challenges.
                  </p>
                  <p>
                    THE NATIONAL MIDWIVES ASSOCIATION OF UGANDA (NMAU) was
                    registered in May 2018 and launched by Hon speaker of
                    parliament of Uganda, Kadaga Rebecca in 2018.
                  </p>
                  <p>
                    It is valuable to acknowledge that NMAU is the best link for
                    midwives in both the public and private sectors in Uganda.
                  </p>
                  <p>
                    We are strongly convinced that Midwifery is holistic pursuit
                    encompassing an understanding of the Social, Emotional,
                    Cultural, Spiritual Psychological aspect of the Woman.
                  </p>
                  <p>
                    NMAU is commited to empowering its members through (1)
                    Increasing their competencies, (2) Representing and building
                    them to the policy table, (3) Making them visible and
                    harnessing their innovativeness to solve reproductive,
                    maternal, adolscent, newborn and child health challenges.
                  </p>
                  <p>
                    Our head office is located at Plot 1255 Kireka Kamuli, Block
                    No 229 P.O. Box 29520, Kampala Uganda.
                  </p>
                  <h3 className="title font-size-25 margin-top-20 uppercase bordered-title white">
                    OUr Mission
                  </h3>
                  <p>
                    To contribute to the improved health of mothers, babies and
                    families of Uganda.
                  </p>
                  <h3 className="title font-size-25 margin-top-20 uppercase bordered-title white">
                    OUr Vision
                  </h3>
                  <p>
                    Championing Midwifery with a difference in Africa and
                    beyond.
                  </p>
                  <h3 className=" title font-size-25 uppercase margin-top-20  bordered-title white">
                    {" "}
                    NMAU objectives
                  </h3>
                  <p>
                    NMAU intends to provide a greater platform for midwives to:{" "}
                  </p>
                  <ol>
                    <li>
                      To foster membership of midwives in public, private not
                      for profit and private for profit in Uganda
                    </li>
                    <li>
                      To strengthen and develop midwifery programs in Uganda
                    </li>
                    <li>
                      To collaborate with national, regional and international
                      midwifery organizations
                    </li>
                    <li>
                      To contribute to the standards and competences of midwives
                      in Uganda
                    </li>
                    <li>
                      To empower midwives in Uganda to acquire leadership and
                      management roles all health related sectors
                    </li>
                    <li>
                      To lobby for resources for sustainability of the
                      association and member welfare
                    </li>
                    <li>
                      To conduct research to inform policy, standards and
                      guideline formulations on midwifery
                    </li>
                  </ol>
                  <h3 className=" title font-size-25 uppercase margin-top-20  bordered-title white">
                    {" "}
                    Classes of membership
                  </h3>
                  <ol>
                    <li>Full Member;</li>
                    <li>Life Member;</li>
                    <li>Honorary member;</li>
                    <li>International Member;</li>
                    <li>Junior Member;</li>
                    <li>Associate Member; </li>
                    <li>Community Representative.</li>
                  </ol>
                  <p>
                    Full Member: This is a person who is registered and licensed
                    to practice midwifery in Uganda by Uganda Nurses and
                    Midwives Council (UNMC)
                  </p>
                  <h3 className=" title font-size-25 uppercase margin-top-20  bordered-title white">
                    {" "}
                    Organizational structure
                  </h3>
                  <p>
                    The NAMU Association will be structured in the following
                    manner:
                  </p>
                  <ul>
                    <li>The National executive council</li>
                    <li>The regional Executive committee</li>
                    <li>Secretariat Staff/Management Team</li>
                    <li>Advisory Board</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBody;

{
  /*

<section>
        <div className="container">
          <div className="large">
            <div className="section-content-container">
              <div className="section-content width-800 white-bg box-shadow relative margin-auto border-radius-2">
                <div className="section-body minus-margin-top-80 section-height-300"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

*/
}
