import React from "react";

const AgmBody = () => {
  return (
    <section className=" section-spacing-80 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content width-800 white-bg box-shadow relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-80">
                <div className="text-content bold">
                  <h3 className="title font-size-25 uppercase bordered-title white">
                    BACKGROUND
                  </h3>
                  <p className="section-spacing-20 no-padding-bottom">
                    The National Midwives Association of Uganda (NMAU) is a
                    professional organization that brings together all the
                    midwives in public and private practice with a mission that
                    seeks to contribute to the improved health of mothers,
                    babies and families of Uganda. NMAU fosters engagement of
                    members for quality midwifery education, regulation and
                    practice, as well as multispectral collaboration for
                    achieving the strategic objectives
                  </p>
                  <p>
                    The primary goal of the Annual General Meeting , is to
                    provide a platform for NMAU members to reflect on the
                    achievements and challenges of the past year, discuss
                    important matters related to midwifery, and collectively
                    plan for the future with its members and key stakeholders
                    The AGM demonstrates NMAUs commitment to transparency,
                    accountability, and inclusive decision-making,The AGM for
                    2023 is scheduled to take place on 20th October 2023 aTt
                    Grand Global Hotel Kampala in Uganda.and the proceedings of
                    the AGM shall be in accordance with NMAU constitution.
                  </p>

                  <h3 className="title font-size-25 margin-top-20 uppercase bordered-title white">
                    Objectives
                  </h3>
                  <ol>
                    <li>
                      Review NMAU annual performance report for 2022 _2023{" "}
                    </li>
                    <li>
                      Discuss priorities and strategic direction for 2024{" "}
                    </li>
                    <li>
                      Conduct elections for NMAU national and regional executive
                      committees
                    </li>
                  </ol>
                  <h3 className=" title font-size-25 uppercase margin-top-20  bordered-title white">
                    {" "}
                    Agenda
                  </h3>
                  <ol>
                    <li>Opening Remarks</li>
                    <li>Presentation and adoption of the agenda</li>
                    <li>Communication from the chair</li>
                    <li>Presentation of NMAU progress report</li>
                    <li>Presentation of the finance and audit report</li>
                    <li>Reactions to minute item 3, 4 and 5</li>
                    <li>
                      Breakout Sessions for the discussion of 2024 priority
                      areas related to:
                      <ul className=" margin-left-4">
                        <li>
                          a. Improving Maternal and Newborn Health Outcomes
                        </li>
                        <li>
                          b. Strengthening Midwifery Education and Training
                        </li>
                        <li>c. Advocacy and Policy </li>
                        <li>d. Promoting Midwife-led Continuity of Care</li>
                      </ul>
                    </li>
                    <li>
                      Panel discussion and resolutions for priority areas for
                      2024{" "}
                    </li>
                    <li>
                      Election of national and regional executive committee
                      members
                    </li>
                    <li>Key note speech by the chief guest </li>
                  </ol>
                  <h3 className=" title font-size-25 uppercase margin-top-20  bordered-title white">
                    {" "}
                    The AGM assembly will comprise of the following:
                  </h3>
                  <ol>
                    <li>
                      NMAU Members (registered midwives, nurse-midwives,
                      midwifery students and associate members )
                    </li>
                    <li>
                      Representatives from the Ministry of Health, Education and
                      Uganda nurses and midwives council{" "}
                    </li>
                    <li>Representatives from International Donor Agencies</li>
                    <li>
                      Development Partners and NGOs working in maternal and
                      child health sector
                    </li>
                    <li>Academicians and Researchers </li>
                    <li>
                      Representatives from Regional and District Health Offices
                    </li>
                    <li>
                      Media Personnel, Observers, reporters and invited guests
                      may access NMAU AGM but shall not vote, propose or second
                      motions.
                    </li>
                  </ol>
                  <h3 className=" title font-size-25 uppercase margin-top-20  bordered-title white">
                    {" "}
                    Logistics:
                  </h3>
                  <ul>
                    <li>
                      Grand Global Hotel has been chosen as the location for the
                      AGM due to its central location and adequate facilities to
                      accommodate the expected number of participants (200
                      participants )
                    </li>
                    <li>
                      Registration: Participants are requested to register
                      online through the NMAU website or at designated
                      registration desks on the day of the event.
                    </li>
                    <li>
                      Accommodation: Participants traveling from outside of
                      Kampala are encouraged to make their accommodation
                      arrangements in advance. A list of recommended hotels will
                      be provided on the NMAU website.
                    </li>
                    <li>
                      Travel: Participants are responsible for their
                      transportation to and from the venue.
                    </li>
                    <li>
                      Meals: Refreshments and lunch will be provided during the
                      meeting{" "}
                    </li>
                    <li>
                      Audio-Visual Support: The necessary audio-visual
                      equipment, including projectors and microphones, will be
                      provided for presentations and discussions.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AgmBody;
