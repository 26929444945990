import React from "react";
import Faq from "../reusables/Faq";
import faqsFile from "../../assets/files/faqs.pdf";

const Faqs = () => {
  return (
    <section className=" section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-head">
              <div className="side-head">
                <div className="title-container">
                  <h3 className="section-title title uppercase">
                    Frequently asked questions
                  </h3>
                </div>
              </div>
            </div>
            <div className="section-body section-spacing-50 no-padding-bottom">
              <ul className="faqs-container">
                <Faq faq="What is NMAU?">
                  <p>
                    NMAU is an abbreviation for National Midwives Association of
                    Uganda.
                  </p>
                </Faq>
                <Faq faq="What does NMAU do?">
                  <p>
                    NMAU is a Uganda's Midwives association, commited to
                    empowering members by increasing their competencies,
                    building them to the policy table, making them visible and
                    harnessing their innovativeness to solve reproductive,
                    maternal, adolscent, newborn and child health challenges.
                  </p>
                </Faq>
                <Faq faq="What fees are there for membership?">
                  <p>
                    A membership fee of 50,000 Ugx is paid upon registration.
                    And a 50,000 Ugx paid annually as annual subscription.
                    Payment made via Bank on the association's account. Details
                    below;
                  </p>
                  <br />
                  <p>
                    <strong>A/c Name:</strong>
                    <br />
                    National Midwives Association of Uganda
                    <br />
                    <strong>A/c Number:</strong>
                    <br />
                    3100062983
                    <br />
                    <strong>Bank Name:</strong>
                    <br />
                    Centenary Bank - Mapeera Branch
                  </p>
                </Faq>
                <Faq faq="Who is eligible to become a member?">
                  <ul>
                    <li>Must be a midwife.</li>
                    <li>Must be registered with the council.</li>
                    <li>Must have at least 50,000 Ugx.</li>
                  </ul>
                </Faq>

                <Faq faq="Can nurses and other health care professionals join the association?">
                  <p>
                    Yes. Nurses and other health professionals can join as
                    associate members.
                  </p>
                </Faq>
                <Faq faq="Have more questions?">
                  <div className="buttons-container flex section-spacing-10">
                    <a
                      href="#"
                      className="button dark bold more-button"
                      download
                    >
                      Download FAQs
                    </a>
                    <a
                      href="https://form.jotform.com/nmauwebsite/NMAU-MemberRegistrationForm"
                      className="button green-bg bold white"
                    >
                      Become a Member
                    </a>
                  </div>
                </Faq>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
