import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Faqs from "./components/home/Faqs";
import About from "./pages/About";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import PageNotFound404 from "./pages/PageNotFound404";
import Team from "./pages/Team";
import NMAUGallery from "./pages/NMAUGallery";
import Updates from "./pages/Updates";
import Agm from "./pages/Agm";
import Projects from "./pages/Projects";

export default class App extends React.PureComponent {
  render() {
    return (
      <Router>
        <div className="app" id="page">
          <a href="#content" className="skip-to-content-link">
            Skip to Content
          </a>
          <Header />
          <div className="site-content" id="content">
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/about">
                <About />
              </Route>
              <Route exact path="/agm-concept-note">
                <Agm />
              </Route>
              <Route exact path="/frequently-asked-questions">
                <Faqs />
              </Route>
              <Route exact path="/team">
                <Team />
              </Route>
              <Route exact path="/gallery">
                <NMAUGallery />
              </Route>
              <Route exact path="/updates">
                <Updates />
              </Route>
              <Route exact path="/projects">
                <Projects />
              </Route>
              <Route exact path="/404">
                <PageNotFound404 />
              </Route>

              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    );
  }
}
