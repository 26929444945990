import React from "react";
import { Slide } from "react-reveal";
import people from "../../assets/images/content/coop.jpg";
import eye from "../../assets/images/icons/eye.svg";
import bull from "../../assets/images/icons/bull.svg";

const AboutSnippet = () => {
  return (
    <section className=" section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="sided-section-container flex">
              <Slide left>
                <div className="text-side left">
                  <div className="side-head">
                    <div className="title-container">
                      <h3 className="section-title title uppercase">
                        About NMAU
                      </h3>
                    </div>
                  </div>
                  <div className="side-body section-spacing-40 font-size-16 bold">
                    <p>
                      The National Midwives Association of Uganda (NMAU) is a
                      one-stop center for all Midwives and well-wishers of
                      Midwives in Uganda. It is valuable to acknowledge that we
                      are the best link for midwives in both the public and
                      private sectors.
                    </p>
                    <p className=" margin-top-20">
                      NMAU is commited to empowering its members through (1)
                      Increasing their competencies, (2) Representing and
                      building them to the policy table, (3) Making them visible
                      and harnessing their innovativeness to solve reproductive,
                      maternal, adolscent, newborn and child health challenges.
                    </p>
                    <p className=" margin-top-20">
                      We are strongly convinced that Midwifery is holistic
                      pursuit encompassing an understanding of the Social,
                      Emotional, Cultural, Spiritual Psychological aspect of the
                      Woman.
                    </p>
                    <p className=" margin-top-20">
                      Our head office is located at Plot 1255 Kireka Kamuli,
                      Block No 229 P.O. Box 29520, Kampala Uganda.
                    </p>
                  </div>
                  <div className="side-footer">
                    <div className="cta-container">
                      <a
                        href="/about"
                        className=" button bold green-bg white width-48"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide right>
                <div className="image-side">
                  <div className="image-container">
                    <img src={people} className="image border-radius-2" />
                  </div>
                  <div className="image-side-float-content">
                    <div className="image-side-text white text-shadow">
                      <div className="missvis">
                        <div className="vision">
                          <div className="flex justify-center">
                            <img src={eye} alt="Eye" className="auto" />
                            <h3>VISION</h3>
                          </div>
                          <p>
                            Championing Midwifery with a difference in Africa
                            and beyond.
                          </p>
                        </div>
                        <div className="mission margin-top-20">
                          <div className="flex justify-center">
                            <img src={bull} alt="Eye" className="auto" />
                            <h3>MISSION</h3>
                          </div>
                          <p>
                            To contribute to the improved health of mothers,
                            babies and families of Uganda.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSnippet;
