import React from "react";
import safe from "../../assets/images/gallery/safe.jfif";
import adol from "../../assets/images/gallery/adol.jfif";
import teso from "../../assets/images/gallery/teso.jfif";
import kaba from "../../assets/images/gallery/kaba.jfif";
import kabal from "../../assets/images/gallery/kabal.jfif";
import tesoo from "../../assets/images/gallery/tesoo.jfif";
import img1 from "../../assets/images/gallery/img1.jpeg";
import img2 from "../../assets/images/gallery/img2.jpeg";
import img3 from "../../assets/images/gallery/img3.jpeg";
import img4 from "../../assets/images/gallery/img4.jpeg";
import img5 from "../../assets/images/gallery/img5.jfif";
import img6 from "../../assets/images/gallery/img6.jfif";
import gg1 from "../../assets/images/gallery/gg1.JPG";
import gg2 from "../../assets/images/gallery/gg2.JPG";
import gg3 from "../../assets/images/gallery/gg3.JPG";
import gg4 from "../../assets/images/gallery/gg4.JPG";
import gg5 from "../../assets/images/gallery/gg5.JPG";
import gg6 from "../../assets/images/gallery/gg6.JPG";
import gg7 from "../../assets/images/gallery/gg7.JPG";
import gg8 from "../../assets/images/gallery/gg8.JPG";
import gg9 from "../../assets/images/gallery/gg9.JPG";
import gg10 from "../../assets/images/gallery/gg10.JPG";
import gg11 from "../../assets/images/gallery/gg11.JPG";
import gg12 from "../../assets/images/gallery/gg12.JPG";
import gg13 from "../../assets/images/gallery/gg13.JPG";
import u1 from "../../assets/images/gallery/u1.jpg";
import u2 from "../../assets/images/gallery/u2.jpg";
import u3 from "../../assets/images/gallery/u3.jpg";
import u4 from "../../assets/images/gallery/u4.jpg";
import u5 from "../../assets/images/gallery/u5.jpg";
import u6 from "../../assets/images/gallery/u6.jpg";
import u7 from "../../assets/images/gallery/u7.jpg";
import u8 from "../../assets/images/gallery/u8.jpg";
import u9 from "../../assets/images/gallery/u9.jpg";
import GalleryCard from "../reusables/GalleryCard";

const Gallery = () => {
  return (
    <section className=" section-spacing-80 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content width-800 white-bg box-shadow relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-80">
                <div className="padding-16 center">
                  <div className="section-head section-spacing-20">
                    <h4>AS IT HAPPENS AT OUR EVENTS</h4>
                  </div>
                  <div className="gallery-container">
                    <GalleryCard
                      galleryImage={u5}
                      galleryImageCaption="At the official inauguration of the new National Executive Committe for National Midwives Association of Uganda for 2023-2027 on 16th February 2024"
                    />
                    <GalleryCard
                      galleryImage={u6}
                      galleryImageCaption="At the official inauguration of the new National Executive Committe for National Midwives Association of Uganda for 2023-2027 on 16th February 2024"
                    />
                    <GalleryCard
                      galleryImage={u7}
                      galleryImageCaption="At the official inauguration of the new National Executive Committe for National Midwives Association of Uganda for 2023-2027 on 16th February 2024"
                    />
                    <GalleryCard
                      galleryImage={u8}
                      galleryImageCaption="At the official inauguration of the new National Executive Committe for National Midwives Association of Uganda for 2023-2027 on 16th February 2024"
                    />
                    <GalleryCard
                      galleryImage={u1}
                      galleryImageCaption="SIDA, UNFPA, NMAU meeting at Naguru Hospital on 9th Feb 2024"
                    />
                    <GalleryCard
                      galleryImage={u2}
                      galleryImageCaption="SIDA, UNFPA, NMAU meeting at Naguru Hospital on 9th Feb 2024"
                    />
                    <GalleryCard
                      galleryImage={u3}
                      galleryImageCaption="SIDA, UNFPA, NMAU meeting at Naguru Hospital on 9th Feb 2024"
                    />
                    <GalleryCard
                      galleryImage={u4}
                      galleryImageCaption="SIDA, UNFPA, NMAU meeting at Naguru Hospital on 9th Feb 2024"
                    />
                    <GalleryCard
                      galleryImage={u9}
                      galleryImageCaption="SIDA, UNFPA, NMAU meeting at Naguru Hospital on 9th Feb 2024"
                    />
                    <GalleryCard
                      galleryImage={safe}
                      galleryImageCaption="During the annual safe motherhood conference 2022, great midwives that have continued to provide better services towards maternal health in Uganda. Well done midwives for the great effort."
                    />
                    <GalleryCard
                      galleryImage={adol}
                      galleryImageCaption="Young midwives leaders attended 2nd annual adolescents and young people's conference at Golf course Hotel Kampala Uganda."
                    />
                    <GalleryCard
                      galleryImage={teso}
                      galleryImageCaption="The President NMAU dessiminated the midwifery strategic plan in both Teso and Karamoja regions. Regional Committees were elected."
                    />
                    <GalleryCard
                      galleryImage={kaba}
                      galleryImageCaption="Midwives celebrated their day in kabale. We appreciate all the stake holders that endevoured to see this day a success.# ICM"
                    />
                    <GalleryCard
                      galleryImage={kabal}
                      galleryImageCaption="Midwives celebrated their day in kabale. We appreciate all the stake holders that endevoured to see this day a success"
                    />
                    <GalleryCard
                      galleryImage={tesoo}
                      galleryImageCaption="The President NMAU dessiminated the midwifery strategic plan in both Teso and Kramoja regions. Regional Committees were elected."
                    />
                    <GalleryCard
                      galleryImage={img1}
                      galleryImageCaption="Image"
                    />
                    <GalleryCard
                      galleryImage={img2}
                      galleryImageCaption="Image"
                    />
                    <GalleryCard
                      galleryImage={img3}
                      galleryImageCaption="Image"
                    />
                    <GalleryCard
                      galleryImage={img4}
                      galleryImageCaption="Image"
                    />
                    <GalleryCard
                      galleryImage={img5}
                      galleryImageCaption="Image"
                    />
                    <GalleryCard
                      galleryImage={img6}
                      galleryImageCaption="Image"
                    />
                    <GalleryCard
                      galleryImage={gg1}
                      galleryImageCaption="NMAU Members at Grand Global 2023"
                    />
                    <GalleryCard
                      galleryImage={gg2}
                      galleryImageCaption="NMAU Members at Grand Global 2023"
                    />
                    <GalleryCard
                      galleryImage={gg3}
                      galleryImageCaption="NMAU Members at Grand Global 2023"
                    />
                    <GalleryCard
                      galleryImage={gg4}
                      galleryImageCaption="NMAU Members at Grand Global 2023"
                    />
                    <GalleryCard
                      galleryImage={gg5}
                      galleryImageCaption="NMAU Members at Grand Global 2023"
                    />
                    <GalleryCard
                      galleryImage={gg6}
                      galleryImageCaption="NMAU Members at Grand Global 2023"
                    />
                    <GalleryCard
                      galleryImage={gg7}
                      galleryImageCaption="NMAU Members at Grand Global 2023"
                    />
                    <GalleryCard
                      galleryImage={gg8}
                      galleryImageCaption="NMAU Members at Grand Global 2023"
                    />
                    <GalleryCard
                      galleryImage={gg9}
                      galleryImageCaption="NMAU Members at Grand Global 2023"
                    />
                    <GalleryCard
                      galleryImage={gg10}
                      galleryImageCaption="NMAU Members at Grand Global 2023"
                    />
                    <GalleryCard
                      galleryImage={gg11}
                      galleryImageCaption="NMAU Members at Grand Global 2023"
                    />
                    <GalleryCard
                      galleryImage={gg12}
                      galleryImageCaption="NMAU Members at Grand Global 2023"
                    />
                    <GalleryCard
                      galleryImage={gg13}
                      galleryImageCaption="NMAU Members at Grand Global 2023"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
